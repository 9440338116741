.text-color-primary {
    color: var(--ion-color-primary);
    font-weight: 600;
    // color: #205eda;
    text-align: center;
}

.box-shadow-offset {
    background-color: #ebf0f5;
    border-radius: 13px;
    box-shadow:
        4px 4px 10px 0px #083c772f,
        -4px -4px 10px 0px #ffffff;
}

.box-shadow-inset {
    background-color: #ebf0f5;
    border-radius: 13px;
    box-shadow:
        4px 4px 5px 0px #083c772f inset,
        -4px -4px 5px 0px #ffffff inset,
        4px 4px 5px 0px #083c772f,
        -4px -4px 5px 0px #ffffff;
}


.button-effect {
    --background-color: #ebf0f5;
    --border-radius: 13px;
    --box-shadow:
        4px 4px 10px 0px #083c772f,
        -4px -4px 10px 0px #ffffff;
    transition: 0.5s ease;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    // color: var(--ion-color-primary);
    font-weight: 600;
    color: rgba(0, 0, 0, 0.623);
}

.button-effect:hover {
    --box-shadow:
        8px 8px 15px 0px #083c772f,
        -8px -8px 15px 0px #ffffff;
}

.button-effect:active {
    transform: scale(0.9);
}