/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.validation-msg {
    display: none;
}

.ion-invalid .validation-msg {
    display: block;
}

ion-item {
    --border-width: 1.8px;
    --border-radius: 5px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 98%;
    overflow: hidden;

    
   

    th {

        border-right: 1px solid #083c772f;
    
    }

    td {
        border-right: 1px solid #083c772f;
        border-top: 1px solid #083c772f;
        
    }

    td,
    th {
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #083c772f;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
    }
}

ion-icon {
    font-size: 25px;
    --ionicon-stroke-width: 20px;
    pointer-events: none;
}


// ------------------------------------------------------------------------

// I added the following css -


@import "theme/crm.scss";

:root {
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;
}
